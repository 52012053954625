// -----------------------------------------------------------------------------
// This file contains img block styles.
// 
// Used in: public/component-image.html
// -----------------------------------------------------------------------------

.img {
	margin-bottom: 15px;
	color: $gray-color;
	font-weight: 900;
	font-size: 16px;

	&-bg {
		background: $light-gray-color;
	}

	&-rounded {
		border-radius: 4px;
	}

	&-square {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80px;
		height: 80px;
		overflow: hidden;
		color: $gray-color;
		transition: .25s;

		&:not(:last-child) {
			margin-right: 10px;
		}

		&-lg {
			width: 120px;
			height: 120px;
		}

		&-sm {
			width: 50px;
			height: 50px;
		}

		&-xs {
			width: 35px;
			height: 35px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	img {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
		
		&:before {
			content: 'Image loading failed';
			display: block;
			font-weight: 400;
			font-size: 13px;
			padding: 25px 10px;
		}

		&:after {
			content: "(url: " attr(src) ")";
			display: block;
			font-size: 12px;
			padding-bottom: 15px;
		}
	}
}

a.img-square {
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		opacity: .5;
	}
}

*[class*="img-"]{
	margin-bottom: 8px;
}

.remove-image-hover {
	background-color: #1f1f1f10;
	margin: 1px;
	padding: 15px;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;

	&:before {
		background-color: rgba(0,0,0,.8);
		color: white;
		display: flex;
		font-weight: bold;
		align-items: center;
		content: "REMOVER";
		height: 30px;
		justify-content: center;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		z-index: 2;
		text-align: center;
		top: calc(50% - 15px);
		width: 100%;
	}

	&:hover {
		&:before {
			opacity: 1;
		}
	}

	img {
		// float: left;
		position: relative;
		width: 100%;
		z-index: 1;
	}
}


 
.sortable-list {
    max-width: 300px;
    margin: 0 auto;
 
	ul {
		list-style-type: none;
		padding: 0;
	}
	 
	li {
		background-color: #f0f0f0;
		padding: 10px;
		margin: 5px;
		cursor: grab;
	}
	
	img {
		max-width: 120px;
	}
}