
.tag-container {
  display: inline-block;
  width: 100%;

  &--border {
    border: 1px solid #ccc;
    padding: 5px;
  }
}

.tag {
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 13px 16px;
  margin-right: 10px;
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
}

.tag input {
  border: none;
  outline: none;
  font-size: 14px;
}